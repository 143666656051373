<template>
  <div
    v-loading="loading"
    class="book-info"
  >
    <div class="book-info-wrapper">
      <div
        v-for="field in activeFields"
        :key="field.id"
        class="book-info__row"
      >
        <r-text color-type="subhead">
          {{ field.name }}
        </r-text>
        <r-date-picker
          v-if="field.datatype === 'datetime' || field.type === 'datetime'"
          v-model="field.value"
          class="r-date-picker"
          type="date"
          name="date"
          format="dd.MM.yyyy HH:mm"
          :clearable="true"
          :disabled="isDisabled(field.title, field)"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
        <r-date-picker
          v-if="field.datatype === 'date' || field.type === 'date'"
          v-model="field.value"
          class="r-date-picker"
          type="date"
          name="date"
          format="dd.MM.yyyy"
          :clearable="true"
          :disabled="isDisabled(field.title, field)"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
        <el-input
          v-if="field.type === 'input'"
          v-model="field.value"
          class="r-input"
          placeholder="Введите текст"
          :disabled="isDisabled(field.title, field)"
        />
        <el-switch
          v-if="field.type === 'boolean'"
          v-model="field.value"
          :disabled="isDisabled(field.title, field)"
          class="r-switch"
        />
        <el-input
          v-else-if="(field.type === 'select' || field.type === 'multi-select') && isDisabled(field.title, field)"
          :value="getDisabledSelectValueRead(field)"
          class="r-input"
          type="textarea"
          :disabled="isDisabled(field.title, field)"
        />
        <el-select
          v-else-if="field.type === 'select' || field.type === 'multi-select'"
          v-model="field.value"
          class="r-select"
          placeholder="Выбрать"
          :multiple="field.type === 'multi-select'"
          :disabled="isDisabled(field.title, field)"
          :loading="selectLoading"
          filterable
          clearable
          @visible-change="getDropDownData($event, field)"
        >
          <el-option
            v-for="item in getReflectionOptionsRead(field)"
            :key="item.id"
            :label="getReflectionLabelAttr(field, item)"
            :value="item.id"
          />
        </el-select>
      </div>
      <files-comments
        v-if="modalActive && modalActive.id"
        :id="modalActive.id"
        wrap
        closed
        column
        :source_id="activeBookSourceId"
      />
    </div>
    <div class="book-info__control">
      <r-button
        simple
        @click="cancel"
      >
        Отменить
      </r-button>
      <r-button
        type="success"
        :disabled="!hasChanges"
        @click="beforeSave"
      >
        Сохранить изменения
      </r-button>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'
import { notifyFactory } from '@/utils'
import filesComments from '@/components/files-comments/files-comments'
import { exceptionsFields } from './configs'
import { dateChecker, falseChecker, parseValue, getObjectConfig } from './helpers'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    filesComments
  },
  data() {
    return {
      reflectionsData: {},
      activeFields: [],
      initialData: [],
      selectedObjects: [],
      activeObject: null,
      loading: false,
      selectLoading: false
    }
  },
  computed: {
    modalActive() {
      return this.$store.state.book.modalActive || null
    },
    initialActiveFields() {
      return this.$store.state.book.activeFields?.fields || null
    },
    reflectionsFields() {
      return this.initialActiveFields?.filter(f => f.reflection)?.map(f => {
        return {
          ...f,
          ...f.reflection
        }
      })
    },
    hasChanges() {
      return this.activeFields?.some(e => {
        const initialValue = this.initialData.find(l => l.title === e.title)?.value

        if (e.type === 'boolean') {
          return !!e.value !== !!initialValue
        } else if (e.type === 'multi-select') {
          return !(!e.value?.length && !initialValue) && !isEqual(e.value, initialValue)
        } else {
          if (Array.isArray(e.value)) return !isEqual(e.value, initialValue)
          else return e.value !== initialValue
        }
      })
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    activeBookSourceId() {
      return this.activeBook.source_id || null
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalHasChanges',
        value: val
      })
    }
  },
  async created() {
    try {
      this.loading = true

      await this.loadObject()
    } catch (e) {
      throw new Error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadReflections(ref_source_id) {
      try {
        let reflection = this.initialActiveFields.filter(e => e.reflection)
        if (ref_source_id) {
          reflection = reflection.filter(e => e.source_id === ref_source_id && !this.reflectionsData[e.name])
        }
        this.isLoading = true
        this.selectLoading = true
        await Promise.all(
          reflection.map(async({ reflection }) => {
            const source_name = reflection.name
            const source_id = reflection.source_id
            if (this.reflectionsData[source_name]) return

            const config = { only: ['id', reflection.default_show_attribute].filter(e => e) }
            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url: `objectInfo/${source_id}?config=${JSON.stringify(config)}`
            })

            this.reflectionsData[source_name] = data
          })
        )
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
        this.selectLoading = false
      }
    },
    async loadObject() {
      try {
        const config = getObjectConfig(this.modalActive.id, this.initialActiveFields)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.activeBookSourceId}?config=${JSON.stringify(config)}`
        })

        this.activeObject = data[this.modalActive.id] || {}

        this.setActiveFields()
      } catch (e) {
        throw new Error(e)
      }
    },
    getDisabledSelectValue(field) {
      const options = this.getReflectionOptions(field) || []
      if (field.deReflect) {
        return field.value?.map(e => {
          const value = e?.[field?.attrName || 'name']

          return dateChecker(value) || '- '
        })?.join(', ')
      }

      if (!Array.isArray(field.value)) {
        return options?.[field.value]?.[field?.attrName || 'name'] || field.value
      }

      return field.value?.map(e => {
        const value = options?.[e]?.[field?.attrName || 'name']

        return dateChecker(value) || '- '
      })?.join(', ')
    },
    getDisabledSelectValueRead(field) {
      const options = this.getReflectionOptionsRead(field) || []
      if (field.deReflect) {
        return field.value?.map(e => {
          const value = e?.[field?.default_show_attribute || 'name']

          return dateChecker(value) || '- '
        })?.join(', ')
      }
      if (!Array.isArray(field.value)) {
        if (options && options.length > 0) {
          return options[0][field.default_show_attribute || 'name'] || field.value
        } else {
          return field.value
        }
      }

      return field.value?.map(e => {
        const value = options?.[e]?.[field?.attrName || 'name']

        return dateChecker(value) || '- '
      })?.join(', ')
    },
    getReflectionLabelAttr(field, item) {
      if (field.attrName) {
        return item?.[field.attrName] === true ? '✓' : item?.[field.attrName] || '-'
      }
      if (field.reflection) {
        const attrName = field.default_show_attribute

        return item[attrName] || item?.name || '-'
      }
      return item.name || '-'
    },
    isDisabled(field, fieldObject) {
      const fieldInfo = this.initialActiveFields?.find(
        e => e.source_name === field
      )
      if (exceptionsFields.includes(field)) return true
      return fieldInfo?.read_only || fieldInfo?.system_field || fieldObject?.read_only
    },
    setActiveFields() {
      this.selectedObjects = cloneDeep([this.activeObject])

      this.activeFields = this.initialActiveFields
        ?.filter(k => {
          return !k.system_field
        })
        ?.map((e, i) => {
          let type = this.getFieldType(e)

          const val = this.activeObject?.[e.origin_title] || this.activeObject?.[e.title]
          let value = val

          if (e.type === 'jsonb') {
            if (val && Array.isArray(val)) {
              if (typeof val?.[0] === 'object') {
                value = val?.map(v => v?.name || 'нет значения')?.join(', ')
              } else {
                value = val && Array.isArray(val) ? val?.join(', ') : ''
              }
            }
          } else if (type === 'multi-select') {
            if (e.reflectParse) {
              type = 'input'
            } else {
              value = val?.map(e => e.id) || null
            }
          }

          if (type === 'select') {
            if (['created_user', 'updated_user'].includes(e.source_name)) {
              value = value?.name
            } else {
              value = value?.id
            }
          }
          return {
            id: i,
            name: e.alias || e.title,
            reflection: e?.reflection?.source_id || null,
            default_show_attribute: e?.reflection?.default_show_attribute,
            read_only: !!e?.read_only,
            system_field: !!e?.system_field,
            attrName: e?.attrName || null,
            deReflect: e?.deReflect,
            title: e.origin_title || e.title,
            origin_title: e.origin_title,
            value,
            type
          }
        }).sort((a, b) => {
          if (b.read_only) return -1
          if (!b.read_only) return 1
          return 0
        })

      this.initialData = cloneDeep(this.activeFields)
    },
    getFieldType(field) {
      switch (field?.reflection?.type) {
        case 'has_many_through':
        case 'has_many':
          return 'multi-select'
        case 'belongs_to':
        case 'has_one':
          return 'select'
        default:
          if (field.type === 'boolean') return 'boolean'
          if (field.type === 'datetime' || field.datatype === 'datetime') return 'datetime'
          if (field.type === 'date' || field.datatype === 'date') return 'date'
          return 'input'
      }
    },
    getReflectionOptions(field) {
      if (!field.reflection) return []
      return this.reflectionsData?.[field.title]
    },
    getReflectionOptionsRead(field) {
      let objectValue = this.activeObject?.[field.title]
      if (objectValue) {
        objectValue = Array.isArray(objectValue) ? objectValue : [objectValue]
      }
      return this.reflectionsData?.[field.title] || objectValue || []
    },
    getDropDownData(event, field) {
      if (event) {
        this.loadReflections(field.reflection)
      }
    },
    cancel() {
      const title = this.$t('before:title')
      const message = this.$t('before-cancel:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')

      this.$confirm(message, title, {
        customClass: 'r-message-box',
        closeOnPressEscape: true,
        closeOnClickModal: false,
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.$store.commit('BOOK_TOGGLE_MODAL', false)
        })
        .catch(() => {
        })
    },
    beforeSave() {
      if (!this.hasChanges) return

      const warningText = 'Сохранить все внесённые изменения?'
      const warningTitle = 'Сохранить изменения'
      const confirmButtonText = 'Сохранить'
      const cancelButtonText = 'Нет'
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.saveChanges()
        })
        .catch(() => {
        })
    },
    async saveChanges() {
      this.loading = true
      const { data, hasMany } = this.getChangedValues()

      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.activeBookSourceId}`,
          data
        })

        if (hasMany.length) {
          await Promise.all(
            hasMany.map(async e => {
              const { reflection, value } = e

              if (!reflection?.source_id || !value?.length) return

              const items = e.value?.map(v => {
                return {
                  id: v,
                  [reflection.foreign_key]: null
                }
              })
              await this.$store.dispatch('PUT_REQUEST', {
                url: `objectInfo/${reflection.source_id}`,
                data: items
              })
            })
          )
        }
        this.$notify(
          notifyFactory('success', 'Сохранение', 'Данные успешно сохранены')
        )
        this.$store.commit('UPDATE_ACTIVE_TABLE', true)
        this.$store.commit('BOOK_TOGGLE_MODAL', false)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getParsedActiveObject() {
      const parsed = this.activeObject

      this.activeFields.forEach(({ title, type }) => {
        switch (type) {
          case 'select':
            parsed[title] = parsed[title]?.id
            break
          case 'multi-select':
            parsed[title] = parsed[title]?.map(e => e.id)
            break
        }
      })

      return parsed
    },
    getChangedValues() {
      const newData = cloneDeep(this.selectedObjects)
      const changedValues = []
      const changedRows = {}
      const hasMany = []

      newData.forEach(e => {
        const item = {}

        this.activeFields.forEach(({ title, read_only, value, type }) => {
          if (read_only) return
          if (['created_user', 'updated_user']?.includes(title)) return

          if (Array.isArray(value)) {
            item[title] = value
          } else if (!Array.isArray(value)) {
            item[title] = parseValue(value, type)
          }
        })

        changedRows[e.id] = item
      })

      const activeObject = this.getParsedActiveObject()

      for (const key in changedRows) {
        const changedRowData = changedRows[key]
        const modifiedFields = {}
        for (const fieldKey in changedRowData) {
          const reflection = this.reflectionsFields?.find(e => e.reflection?.name === fieldKey)
          const initialFalse = falseChecker(activeObject)
          const changedFalse = falseChecker(changedRowData[fieldKey])
          if (!(initialFalse && changedFalse) &&
            !isEqual(activeObject[fieldKey], changedRowData[fieldKey])
          ) {
            if (reflection) {
              const foreign_key = reflection.foreign_key
              if (!foreign_key) continue
              if (reflection.through) {
                const through = activeObject[reflection.through]
                if (!through) continue
                modifiedFields[reflection.through] = []
                changedRowData[fieldKey].forEach(r => {
                  const initialIndex = through.findIndex(
                    q => q[foreign_key] === r
                  )
                  if (initialIndex === -1) {
                    modifiedFields[reflection.through].push({
                      vehicle_id: key,
                      [foreign_key]: r
                    })
                  } else {
                    through.splice(initialIndex, 1)
                  }
                })
                through.forEach(p => {
                  modifiedFields[reflection.through].push({
                    id: p.id,
                    disabled: true
                  })
                })
              } else {
                if (reflection.type === 'has_many') {
                  modifiedFields[reflection.name] = changedRowData[fieldKey]?.map(l => ({ id: l }))
                  const deleted = activeObject[reflection.name]?.filter(
                    l => !changedRowData[fieldKey]?.includes(l)
                  )

                  if (deleted?.length) {
                    hasMany.push({
                      reflection,
                      value: deleted
                    })
                  }
                } else {
                  modifiedFields[foreign_key] = changedRowData[fieldKey]
                }
              }
            } else {
              modifiedFields[fieldKey] = changedRowData[fieldKey]
            }
          }
        }
        modifiedFields.id = key
        changedValues.push({ ...modifiedFields, _action: 'updated' })
      }

      return { data: changedValues, hasMany }
    }
  }
}
</script>

<style lang="scss" scoped>
.book-info {
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 12px;

  &-wrapper {
    height: calc(100vh - 280px);
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: hidden;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    > * {
      &:first-child {
        margin-right: 16px;
        width: 160px;
      }

      &:last-child {
        width: calc(100% - 160px - 16px);
      }
    }
  }

  &__control {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: end;
    margin-top: 12px;
  }

  .el-textarea {
    &.is-disabled {
      opacity: 0.5;
    }
  }
}
</style>
